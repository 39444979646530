<template>
  <div class="yimiao">
    <div>
      <!-- <div style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        ">
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%"
            src="../../assets/返淇.png"
            alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 30px;left:100px">
          <div style="font-size: 18px">返洛人员报备</div>
        </div>
      </div> -->
      <div>
        <van-cell-group>
          <van-field v-model="datafrom.OrgName"
            required
            @click="idListOrg = true"
            readonly="readonly"
            label="社区"
            placeholder="请选择社区" />
          <van-popup v-model="idListOrg"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="OrganList"
              value-key="organname"
              @cancel="idListOrg = false"
              @confirm="onidListOrg">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <!-- <van-cell-group>
          <van-cell required
            title="企业类别">
            <van-radio-group v-model="datafrom.ESort"
              direction="horizontal">
              <van-radio name="1">重点企业</van-radio>
              <van-radio name="2">普通企业</van-radio>
            </van-radio-group>
          </van-cell>          <van-radio-group v-model="datafrom.ESort"
            direction="horizontal">
            <van-radio name="1">重点企业</van-radio>
            <van-radio name="2">普通企业</van-radio>
          </van-radio-group>
        </van-cell-group> -->
        <div class="submit"
          style="padding:10px 7px;">
          <span style="color: red; font-size: 14px; letter-spacing: 2px">*</span>
          <span style="color: #646566;font-size: 14px;">企业类别</span>
          <span style="color: #646566;font-size: 14px;margin-left:20px; ">
            <van-radio-group v-model="datafrom.ESort"
              direction="horizontal"
              class="qiye">
              <van-radio name="1">重点企业</van-radio>
              <van-radio name="2">普通企业</van-radio>
            </van-radio-group>
          </span>

        </div>

        <!-- <van-cell-group>
          <van-field v-model="datafrom.Region"
            required
            @click="idListRId = true"
            readonly="readonly"
            label="小区(组)"
            placeholder="请选择您所住小区(组)" />
          <van-popup v-model="idListRId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="ridlist"
              value-key="Title"
              @cancel="idListRId = false"
              @confirm="onidListRId">
            </van-picker>
          </van-popup>
        </van-cell-group> -->
        <van-cell-group>
          <van-field v-model="datafrom.Name"
            required
            label="企业名称"
            placeholder="请输入企业名称" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.ShortName"
            label="简 称"
            placeholder="请输入企业简称" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.LicCode"
            required
            label="营业执照编号"
            placeholder="请输入企业营业执照编号" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Summary"
            label="企业简介"
            placeholder="请输入企业简介" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Charger"
            label="负责人"
            required
            placeholder="请输入负责人" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.TouchTel"
            label="联系方式"
            required
            placeholder="请输入负责人联系方式" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Address"
            required
            label="详细地址"
            placeholder="请输入企业详细地址" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.CoTel"
            label="固 话"
            placeholder="请输入固话" />
        </van-cell-group>

        <van-field v-model="datafrom.NationalityName"
          label="企业类型"
          placeholder="请选择企业类型"
          @click="shopTypePicker = true"
          readonly="readonly"
          clickable />
        <van-popup v-model="shopTypePicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="shopType"
            value-key="Name"
            @confirm="shopTypeQr"
            @cancel="shopTypePicker = false" />
        </van-popup>
        <van-cell-group>
          <van-field v-model="datafrom.IndCode"
            label="行业编码"
            placeholder="请输入行业编码" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.RegCapital"
            label="注册资本"
            placeholder="请输入注册资本" />
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.CoScale"
            label="企业规模"
            placeholder="请输入企业规模" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.CoEMail"
            label="邮 箱"
            placeholder="请输入企业邮箱" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Homepage"
            label="主 页"
            placeholder="请输入企业主页" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.MicroBlog"
            label="微 博"
            placeholder="请输入企业微博" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.WeChat"
            label="微信公众号"
            placeholder="请输入企业微信公众号" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.FoundDate"
            label="成立日期"
            @click="datafromOutTime = true"
            readonly="readonly"
            placeholder="请选择成立日期" />
          <van-popup v-model="datafromOutTime"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择成立日期"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="datafromOutTime = false"
              @confirm="ondatafromOutTime">
            </van-datetime-picker>
          </van-popup>
        </van-cell-group>

        <van-cell-group>
          <van-field v-model="datafrom.BusinessScope"
            label="经营范围"
            placeholder="请输入企业经营范围" />
        </van-cell-group>

        <div class="submit"
          style="padding:10px 7px;">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
          <span style="color: #646566;font-size: 14px; ">营业执照</span>
        </div>
        <van-uploader v-model="jkImageList"
          multiple
          capture="camera"
          class="imgs"
          :max-count="1"
          :after-read="afterRead"
          :before-delete="jkDelete"
          :deletable="true" />
        <!-- <van-cell-group title-style="width:20%;">
          <van-cell title="定位">
            <van-radio-group v-model="dwIndex"
              direction="horizontal">
              <van-radio name="1"
                @click="dwXuanze(1)">是</van-radio>
              <van-radio name="2"
                @click="dwXuanze(2)">否</van-radio>
            </van-radio-group>
          </van-cell>
        </van-cell-group> -->
        <div class="submit"
          style="padding:10px 7px;">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
          <span style="color: #646566;font-size: 14px; ">获取当前定位</span>
          <span style="color: #646566;font-size: 14px;margin-left:20px; ">
            <van-radio-group v-model="dwIndex"
              direction="horizontal"
              class="qiye">
              <van-radio name="1"
                @click="dwXuanze(1)">是</van-radio>
              <van-radio name="2"
                @click="dwXuanze(2)">否</van-radio>
            </van-radio-group>
          </span>

        </div>
        <div id="allmap"
          v-show="dWshow"
          style="height: 160px;width: 90%;margin: auto;"></div>
      </div>
      <!-- <div class="buttons">
        <van-button type="info" @click="postfrom()">提交</van-button>
      </div> -->
      <div class="buttons">
        <van-button type="info"
          style="margin:10px 0;"
          v-if="bangdingShow"
          @click="postfrom(1)">提交</van-button>
        <van-button type="info"
          style="margin:10px 0;"
          v-if="!bangdingShow"
          @click="postfrom(2)">微信绑定</van-button>
        <van-button style="border: 1px solid #e6e6e6;margin:10px 0;"
          type="
          default"
          @click="fanhui()">返回</van-button>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="//api.map.baidu.com/api?v=3.0&ak=L2s5R0CE405alnjKlsp35mNTySNAvZtd"></script>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
import { loadBMap } from "../fangyi/baiduLocation.js";
import {
  GetCodeEntrysList,
} from "@/api/wangge";
Vue.use(Toast);
import {
  WeGetRegionList,
  WeGetBuildRoomList,
  WxSaveEnterprise,
  WeGetAppletOrganList,
  GetOrgansNullToken,
  WxMyGetEnterprise,
  WxReBindEnterprise
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      // 营业执照
      jkImageList: [],
      //商铺图片
      ShopImgList: [],
      OrganList: [],
      ridlist: [],
      BIdlist: [],
      BUIdlist: [],
      BFIdlist: [],
      BRIdlist: [],
      diqu: [],
      guanxi: [],
      minzu: [],
      xveli: [],
      zhuangtai: [],
      hunyin: [],
      ocodelist: [],
      minDate: new Date(2021, 0),
      maxDate: new Date(2025, 0),
      NativeCantonCodedata: [],
      dwIndex: "2",
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ],
      yimiaolist: [
        { id: "0", Name: "未接种" },
        { id: "1", Name: "第一针" },
        { id: "2", Name: "第二针" },
      ],
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        EId: 0,  //id、
        OrgCode: "",
        OrgName: "",
        ESort: "2",
        Name: "",
        ShortName: "",
        EType: "",
        RegCapital: "",
        IndCode: "",
        Charger: "",
        TouchTel: "",
        Address: "",
        Coordinate: "",
        CoScale: "",
        CoTel: "",
        CoEMail: "",
        Homepage: "",
        MicroBlog: "",
        WeChat: "",
        FoundDate: "",
        Summary: "",
        License: "",
        LicCode: "",
        BusinessScope: "",
        OpenID: "",
        NickName: "",
        NetImg: "",
        Remark: "",
      },
      shopType: [],
      shopTypePicker: false, //商铺类型
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromBackTime: false,
      datafromOutTime: false,
      dWshow: false,
      bangdingShow: true,
      bjZuoBiao: "",
    };
  },
  created () {
    this.GetOrgansNullToken();
    this.shopXq()
    this.zidianlist()
  },
  methods: {
    shopXq: function () {
      console.log(this.$route.query);
      if (this.$route.query.add == "1") {
        this.datafrom = {
          EId: 0,  //id、
          OrgCode: "",
          OrgName: "",
          ESort: "2",
          Name: "",
          ShortName: "",
          EType: "",
          RegCapital: "",
          IndCode: "",
          Charger: "",
          TouchTel: "",
          Address: "",
          Coordinate: "",
          CoScale: "",
          CoTel: "",
          CoEMail: "",
          Homepage: "",
          MicroBlog: "",
          WeChat: "",
          FoundDate: "",
          Summary: "",
          License: "",
          LicCode: "",
          BusinessScope: "",
          OpenID: "",
          NickName: "",
          NetImg: "",
          Remark: "",
        }
      } else if (this.$route.query.add == "2" || this.$route.query.add == "3") {
        console.log(32123);
        console.log(this.$route.query.shopId);
        WxMyGetEnterprise({ eId: this.$route.query.shopId }).then((res) => {
          this.bangdingShow = true
          if (res.data.code == 0) {
            console.log(res.data.data);
            this.datafrom = res.data.data;
            //重点监控
            this.bjZuoBiao = res.data.data.Coordinate
            this.datafrom.ESort = res.data.data.ESort + "";
            this.datafrom.OrgCode = res.data.data.OrgCode;
            this.datafrom.OrgName = res.data.data.OrgName;
            // this.datafrom.Region = res.data.data.RName;
            // this.RegionList(res.data.data.OCode);
            //商铺照片
            // if (res.data.data.ShopImg != "") {
            //   this.ShopImgLists = [res.data.data.LicenseUrl];
            //   var arr = [];
            //   this.ShopImgLists.forEach(function (imgUrl) {
            //     arr.push({ url: imgUrl });
            //   });
            //   this.ShopImgList = arr;
            //   this.datafrom.ShopImg = res.data.data.ShopImg;
            // }
            if (res.data.data.License != "") {
              //营业执照
              this.jkImageLists = [res.data.data.LicenseUrl];
              var arrYe = [];
              this.jkImageLists.forEach(function (imgUrl) {
                arrYe.push({ url: imgUrl });
              });
              this.jkImageList = arrYe;
              this.datafrom.License = res.data.data.License;
            }
            this.datafrom.EType = res.data.data.EType;
            this.datafrom.NationalityName = res.data.data.ShopTypeDesc;
            // this.datafrom.Region = res.data.data.RName;
            // this.datafrom.IsMonitor = res.data.data.IsMonitor;
            // console.log(this.ShopImgList);
          }
          if (this.$route.query.add == "3") {
            console.log("重新绑定");
            this.bangdingShow = false
          }
        })
      }
    },
    dwXuanze: function (row) {
      if (row == "1") {
        this.dingwei()
        this.dWshow = true
      } else {
        this.dWshow = false
        console.log(this.bjZuoBiao);
        this.Coordinate = this.bjZuoBiao
      }
    },
    dingwei: function () {
      var that = this;
      console.log(123);
      // 百度地图API功能
      window.initBaiduMapScript = () => {
        that.BMap = window.BMap;
        var map = new window.BMap.Map("allmap");
        var point = new that.BMap.Point(116.331398, 39.897445);
        map.centerAndZoom(point, 18);

        var geolocation = new that.BMap.Geolocation();
        var Coordinate = ""
        geolocation.getCurrentPosition(function (r) {
          if (this.getStatus() == BMAP_STATUS_SUCCESS) {
            console.log(r);
            var mk = new BMap.Marker(r.point);
            map.addOverlay(mk);
            map.panTo(r.point);
            this.Coordinate = [{ "lng": r.point.lng, "lat": r.point.lat, "of": "inner" }]
            console.log(this.Coordinate);
            that.dingweiList(this.Coordinate)
            // alert('您的位置：' + r.point.lng + ',' + r.point.lat);

          }
          else {
            alert('failed' + this.getStatus());
          }
        }, { enableHighAccuracy: true })
        console.log(geolocation);
        this.dingweiList(geolocation)
      };
      loadBMap("initBaiduMapScript");
    },
    dingweiList: function (geolocation) {
      console.log(geolocation);
      this.Coordinate = geolocation
    },
    // 字典查询
    zidianlist: function () {
      // 商铺类型
      GetCodeEntrysList({ kind: "254" }).then((res) => {
        this.shopType = res.data.data;
      });
    },
    // 确认商铺类型
    shopTypeQr (val) {
      this.datafrom.EType = val.Coding;
      this.datafrom.NationalityName = val.Name;
      this.shopTypePicker = false;
    },
    //上传营业执照
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.datafrom.License = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除营业执照
    jkDelete (file) {
      this.jkImageList = []
      this.datafrom.License = ""
    },
    //上传商铺图片
    ShopImgRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.datafrom.ShopImg = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除商铺图片
    xcDelete (file) {
      this.ShopImgList = []
      this.datafrom.ShopImg = ""
    },
    //上传核酸证明
    afterhsRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.datafrom.Testify = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除核酸
    hsDelete (file) {
      this.hsImageList = []
      this.datafrom.Testify = ""
    },
    // 提交
    postfrom: function (row) {
      console.log(this.Coordinate);
      console.log(this.datafrom);
      if (this.datafrom.OrgCode == "") {
        Toast.fail("请选择社区");
        return false;
      }

      if (this.datafrom.Name == "") {
        Toast.fail("请输入企业名称");
        return false;
      }
      if (this.datafrom.LicCode == "") {
        Toast.fail("请输入营业执照编号");
        return false;
      }
      if (this.datafrom.Charger == "") {
        Toast.fail("请输入负责人");
        return false;
      }
      if (this.datafrom.TouchTel == "") {
        Toast.fail("请输入负责人联系方式");
        return false;
      }

      if (this.datafrom.Address == "") {
        Toast.fail("请输入企业详细地址");
        return false;
      }
      this.datafrom.OpenID = getOpenId();
      // this.datafrom.OpenID = "oh5yu5MZRYsqSdFQIXzJ4mnwRAPM";
      if (this.Coordinate == "") {
        this.datafrom.Coordinate = ""
      } else {
        if (this.dWshow) {
          this.datafrom.Coordinate = JSON.stringify(this.Coordinate)
        } else {
          this.datafrom.Coordinate = this.bjZuoBiao
        }

      }
      this.datafrom.IsMonitor = Number(this.datafrom.IsMonitor)
      if (row == "1") {
        WxSaveEnterprise(this.datafrom).then((res) => {
          if (res.data.code == 0) {
            Dialog.alert({
              title: "提示",
              message: "提交成功!",
            }).then(() => {
              this.$router.go(-1)
              // this.$router.push({
              //   name: "register",
              // });
            });
          } else {
            Dialog.alert({
              title: "提示",
              message: "提交失败!" + res.data.msg,
            }).then(() => {
              this.datafrom.IsMonitor = this.datafrom.IsMonitor + ""
              // on close
            });
          }
        })
      } else if (row == "2") {
        WxReBindEnterprise(this.datafrom).then((res) => {
          if (res.data.code == 0) {
            Dialog.alert({
              title: "提示",
              message: "绑定成功!",
            }).then(() => {
              this.$router.go(-1)
              // this.$router.push({
              //   name: "register",
              // });
            });
          } else {
            Dialog.alert({
              title: "提示",
              message: "提交失败!" + res.data.msg,
            }).then(() => {
              this.datafrom.ESort = this.datafrom.ESort + ""
              // on close
            });
          }
        })
      }

    },
    gitocodelist () {
      GetOrgansNullToken().then((res) => {
        this.ocodelist = res.data.data;
      })
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    // 小区(组)列表
    RegionList: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.go(-1)
    },
    // 社区选择器
    onidListOrg (val) {
      this.datafrom.OrgCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      // this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId (val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow (val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },

    // 消防器材年检日期
    ondatafromOutTime (val) {
      this.datafrom.FoundDate = this.dateformat(val);;
      this.datafromOutTime = false;
    },
    // 社区
    onocode (val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      // return `${year}-${month}-${day} ${houser}:${Minutes}`;
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
.qiye {
  margin-left: 30%;
  position: absolute;
  margin-top: -18px;
}
</style>